<template>
  <sm-editable-list
    :controllerName="controllerName"
    :parentName="parentName"
    :breadcrumbs="breadcrumbs"
    :tableCaption="tableCaption"
    :tableHeaders="tableHeaders"
    showEditButton
    showDeleteButton
    @edit="onEdit"
  >
    <template v-slot:actionsDatabase="{ row }">
      <div class="action-database__button-group">
        <sm-button
          v-if="row.name.endsWith('_aud')"
          :isLoading="truncArray.includes(row.name)"
          @click="onTruncateDatabase(row.name)"
          >Очистить базу</sm-button
        >
      </div>
    </template>
  </sm-editable-list>
</template>

<script>
// mixins
import editableList from '@/mixins/editableList.js';
// vuex
import { createNamespacedHelpers } from 'vuex';
// vuex : editableList
const { mapState, mapActions } = createNamespacedHelpers('editableList');
// vuex : dbMaintenance
const { mapActions: mapActionsDbMaintenance } =
  createNamespacedHelpers('dbMaintenance');
// components
import SmEditableList from '@/components/views/SmEditableList.vue';
import SmButton from '@/components/common/buttons/SmButton.vue';

export default {
  name: 'SitesForCustomer',

  components: {
    SmEditableList,
    SmButton,
  },

  mixins: [editableList],

  data() {
    return {
      controllerName: 'Sites',
      parentName: 'Customer',
      tableCaption: 'Сайты клиентов',
      tableHeaders: [
        { text: 'Название', alias: 'name', order: 'name' },
        {
          text: 'Название клиента',
          alias: 'customerName',
          order: 'customerName',
        },
        { text: 'Адрес сайта', alias: 'url', order: 'url' },
        { text: 'Дата создания', alias: 'created', order: 'created' },
        {
          text: 'Не контролировать выгрузку в ГИС ЖКХ',
          alias: 'dontControlGisExport',
          order: 'dontControlGisExport',
        },
        { alias: 'actions' },
        { alias: 'actionsDatabase' },
      ],
      disabledDbMaintenance: null,
      truncArray: [],
    };
  },

  computed: {
    ...mapState(['item']),

    id() {
      return +this.$route.params.id;
    },

    breadcrumbs() {
      return [
        {
          text: 'Клиенты',
          route: { name: 'CustomersMain' },
        },
        {
          text: 'Клиенты',
          route: { name: 'Customers' },
        },
        {
          text: `Редактирование клиента ${this.item.data?.name}`,
          route: { name: 'CustomerEdit', params: { id: this.id } },
        },
        {
          text: 'Сайты клиентов',
        },
      ];
    },
  },

  created() {
    this.getItem({ name: 'Customers', id: this.id });
  },

  methods: {
    ...mapActions(['getItem']),
    ...mapActionsDbMaintenance(['truncateDatabase']),

    onEdit(id) {
      this.$router.push({
        name: 'SiteForCustomerEdit',
        params: {
          id,
          parentId: this.id,
        },
      });
    },

    onTruncateDatabase(databaseName) {
      this.truncArray.push(databaseName);
      this.truncateDatabase({ databaseName }).finally(() => {
        const indexToRemove = this.truncArray.indexOf(databaseName);
        this.truncArray.splice(indexToRemove, 1);
      });
    },
  },
};
</script>

<style lang="scss">
.action-database__button-group {
  width: 120px;
}

.action-database__button-group > .button:first-child {
  margin-bottom: 4px;
}
</style>
